<template>
  <div class="list_body">
    <div :style="{ minHeight: '5vh' }">位置:一级页面/二级页面</div>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <!-- 搜索 -->
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="所属栏目:">
          <a-select v-model="searchForm1.sectionParentsId" style="width: 120px" :allowClear="true">
            <a-select-option :value="section.id" v-for="section in sections" :key="section.id"> {{ section.title }} </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="栏目名称:">
          <div style="background-color: red; width: 20px; higth: 10px"></div>
          <a-input placeholder="请输入" v-model="searchForm1.title" :style="{ display: 'inline' }" :allowClear="true" />
        </a-form-model-item>
        <a-form-model-item label="创建日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="dateRange" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <!-- 新增 -->
      <a-button type="primary" @click="showModal"> 新增 </a-button>
      <!-- 新增/修改 -->
      <a-modal title="新增栏目" :visible="visible" :confirm-loading="confirmLoading" @ok="addOk" @cancel="handleCancel" :mask-closable="false">
        <a-form-model layout="vertical" labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="栏目名称:">
            <a-input v-decorator="['addSection', { rules: [{ required: true, message: '请输入' }] }]" style="width: 220px" v-model="addSection.title" />
          </a-form-model-item>
          <a-form-model-item label="所属栏目:">
            <a-select defaultValue="请选择" style="width: 220px" v-model="addSection.parentId">
              <a-select-option :value="section.id" v-for="section in sections" :key="section.id"> {{ section.title }} </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否推荐:">
            <a-radio-group :default-value="true" v-model="addSection.isRecommend">
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="状态:">
            <a-radio-group :default-value="true" v-model="addSection.status">
              <a-radio :value="true"> 启用 </a-radio>
              <a-radio :value="false"> 禁用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 表格 -->
      <a-table :columns="columns" :data-source="data" :pagination="datapager" @change="handleTableChange" :row-key="(record) => record.id">
        <span slot="num" slot-scope="text, record, index"> {{ (datapager.current - 1) * datapager.pageSize + index + 1 }}</span>
        <span slot="p_name" slot-scope="text">{{ text }}</span>
        <span slot="name" slot-scope="text">{{ text }}</span>
        <a-switch slot="isRecommend" slot-scope="text, record" @change="recommendUpdate(record)" :checked="text" />
        <a-switch slot="status" slot-scope="text, record" @change="statusUpdate(record)" :checked="text" />
        <span slot="created" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <a-button @click="showUpdate(record)">编辑</a-button>
          <a-divider type="vertical" />
          <a-button @click="del(record)" type="danger"> 删除 </a-button>
        </span>
      </a-table>
      <!-- <a-pagination show-size-changer :default-current="3" :total="500" @showSizeChange="onShowSizeChange" /> -->
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'num' }, //customRender值不能叫title
  },
  {
    title: '所属栏目',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'p_name' },
  },
  {
    title: '栏目名称',
    dataIndex: 'title',
    key: 'title',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '是否推荐',
    dataIndex: 'isRecommend',
    key: 'isRecommend',
    scopedSlots: { customRender: 'isRecommend' },
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '创建时间',
    key: 'created',
    dataIndex: 'created',
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
const data = [];
export default {
  data() {
    return {
      //新增
      addOk: this.handleOk,
      visible: false,
      confirmLoading: false,
      addSection: {},
      recommendRadioGroup: { 1: true, 2: false },

      sections: [],
      data, //表格数据，从服务端中获取
      columns, //表头
      dateRange: ['', ''],
      searchForm1: { sectionParentsId: '', title: '', startTime: '', endTime: '' }, //查询参数
      datapager: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取列表 */
    async loadList() {
      //调用服务端接口获取列表
      let result = await this.axios({
        method: 'post',
        url: `section/search/${this.datapager.current}/${this.datapager.pageSize}`,
        data: this.searchForm1,
      });
      let section = await this.axios({
        method: 'get',
        url: `section/list`,
      });
      this.sections = section.data.data;
      var datum = result.data.data;
      this.datapager.total = result.data.total;
      for (let d of datum) {
        if (d.status == '启用') {
          d['status'] = true;
        } else {
          d['status'] = false;
        }
      }
      this.data = JSON.parse(JSON.stringify(datum));
  
    },
    /**确认查询 */
    searchSubmit(e) {
      this.searchForm1.startTime = this.dateRange[0];
      this.searchForm1.endTime = this.dateRange[1];
      this.loadList(); //重新读取列表
      e.preventDefault();
      // return false;
    },
    /**分页 */
    handleTableChange(pagination1, filters, sorter) {
      this.datapager = pagination1; //保存分页信息
      this.loadList(); //重新读取列表
    },
    /**推荐 */
    async recommendUpdate(value) {
      value.isRecommend = !value.isRecommend;
      var updateValue = JSON.parse(JSON.stringify(value));
      if (value.status === true) {
        updateValue['status'] = '启用';
      } else {
        updateValue['status'] = '禁用';
      }
      let result = await this.axios({
        method: 'post',
        url: `section/update`,
        data: updateValue,
      });
      this.loadList();
    },
    /**状态 */
    async statusUpdate(value) {
      var updateValue = JSON.parse(JSON.stringify(value));
      if (value.status === true) {
        updateValue['status'] = '禁用';
      } else {
        updateValue['status'] = '启用';
      }
      let result = await this.axios({
        method: 'post',
        url: `section/update`,
        data: updateValue,
      });
      this.loadList();
    },
    showUpdate(obj) {
      this.visible = true;

      //深拷贝
      this.addSection = JSON.parse(JSON.stringify(obj));

      this.addOk = this.update;
      console.log(this.addSection);
    },
    /**修改 */
    async update() {
      this.confirmLoading = true;
      if (this.addSection.status) {
        this.addSection.status = '启用';
      } else {
        this.addSection.status = '禁用';
      }
      console.log('修改');
      try {
        let result = await this.axios({
          method: 'post',
          url: `section/update`,
          data: this.addSection,
        });
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.addSection = {};
      }, 500);
    },
    /**删除 */
    del(obj) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let result = await that.axios.delete(`section/delete/${obj.id}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
    /**新增页面 */
    showModal() {
      this.visible = true;
    },
    /**新增确定 */
    async handleOk(e) {
      this.confirmLoading = true;
      if (this.addSection.status) {
        this.addSection.status = '启用';
      } else {
        this.addSection.status = '禁用';
      }
      try {
        let result = await this.axios({
          method: 'post',
          url: `section/save`,
          data: this.addSection,
        });
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.addSection = {};
      }, 500);
    },
    /**新增取消 */
    handleCancel(e) {
      this.addSection = {};
      this.visible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.a {
  background-color: aquamarine;
}
</style>
